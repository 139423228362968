
import {
  DealerTypes,
  DealerUserProfile,
} from "@/store/modules/dealer/dealer.types";
import { DefaultState } from "@/types/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const dealer = namespace("Dealer");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
})
export default class DealersView extends Vue {
  @Prop({ default: true }) pagination?: boolean;

  @dealer.State("dealers")
  public dealers!: DealerUserProfile[];

  @dealer.State("add")
  public addDealer!: DefaultState;

  @dealer.Mutation(DealerTypes.SET_ADD_DEALER_DIALOG)
  public setAddDealer!: (addDealer: boolean) => void;

  @dealer.Action(DealerTypes.LOAD_DEALERS)
  public loadDealers!: () => void;

  public query = "";

  public headers = [
    {
      text: "PHOTO",
      align: "center",
      sortable: false,
      value: "photo",
      width: 150,
    },
    {
      text: "NAME",
      sortable: true,
      value: "name",
    },
    {
      text: "DISTRICT",
      align: "center",
      sortable: true,
      value: "district",
    },
    {
      text: "FARMERS",
      align: "center",
      sortable: true,
      value: "farmers",
    },
    {
      text: "PHONE",
      align: "center",
      sortable: true,
      value: "phone",
    },
    {
      text: "ACTIONS",
      align: "center",
      sortable: true,
      value: "actions",
    },
  ];

  closeAddDealerDialog(): void {
    this.setAddDealer(false);
  }

  openAddDealerDialog(): void {
    this.setAddDealer(true);
  }

  mounted(): void {
    this.loadDealers();
  }
}
